import React from "react";
import { Box, Icon } from "@storyofams/react-ui";
import Image from "next/image";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useShopify } from "~context";
import { Star } from "~components/common/Icons";
import {
  Text,
  Button,
  FixedRatio,
  HoverArea,
  ScaleOnHover,
  Heading,
} from "../../../components";
import {
  formatAlgoliaProductsForTracking,
  formatShopifyIdForTracking,
  storeProductClickOrigin,
  track,
} from "../../../lib";
import { CurrencyCode } from "../../../lib/shopify/sdk";
import { shopifyToIntlPrice } from "../../../lib/shopify/utils";
import { AddToCartButton } from "./AddToCartButton";
import { AddToWishlistButton } from "./AddToWishlistButton";
import { DiscountLabel, Labels, StockLabel } from "./Labels";

export const ProductCard = React.memo(
  ({
    small,
    hideStockLabel,
    onClick,
    index,
    fromSearch,
    products,
    isShowRating = true,
    ...product
  }: any) => {
    const formatPrice = (product) => {
      if (product?.priceRange?.minVariantPrice?.amount === "0.0") {
        if (product?.price) {
          return shopifyToIntlPrice({
            currencyCode: CurrencyCode.Eur,
            amount: product.price,
          });
        }
        if (product?.variants?.edges[0]?.node?.priceV2) {
          const variantPrice = product?.variants?.edges[0]?.node?.priceV2;
          return shopifyToIntlPrice(variantPrice);
        }
        // else if (!product?.price) {
        //   const variantPrice = product?.variants?.edges[0]?.node?.priceV2;
        //   return shopifyToIntlPrice(variantPrice);
        // }
      }
      return shopifyToIntlPrice(product?.priceRange?.minVariantPrice);
    };
    const { accessToken } = useShopify();
    const posthog = usePostHog();
    const { pathname } = useRouter();
    const review = product?.meta?.eigenschappen?.product_rating;
    const date1 = new Date(product?.published_at || product?.publishedAt);
    const date2 = new Date();
    const diffDays = Math.ceil(
      Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)
    );
    const isNew = diffDays < 90;
    const inStock =
      product?.variants_inventory_count > 0 || product?.availableForSale;
    const price =
      product.autoDiscount &&
      product?.priceRange?.minVariantPrice?.amount !== "0.0"
        ? (
            Number(product?.priceRange?.minVariantPrice?.amount) -
            (Number(product?.priceRange?.minVariantPrice?.amount) *
              Number(product?.autoDiscount?.value)) /
              100
          ).toFixed(2)
        : null;
    return (
      <HoverArea>
        <Button
          as="a"
          href={`/products/${product?.handle}`}
          width="100%"
          variant="unstyled"
          onClick={(e) => {
            // posthog.capture("Product card clicked", {
            //   source: pathname,
            //   product: product.title,
            // });
            if (onClick) {
              onClick(e);
            }

            storeProductClickOrigin(
              `${product.id}` || formatShopifyIdForTracking(product.id),
              window.location.pathname
            );
            track({
              event: "dl_select_item",
              ecommerce: {
                currencyCode: "EUR",
                click: {
                  actionField: { list: window.location.pathname },
                  products: product.objectID
                    ? formatAlgoliaProductsForTracking({
                        hits: [product],
                      })
                    : [
                        {
                          name: product.title,
                          id: product.variants?.edges?.[0]?.node?.sku || "",
                          product_id: formatShopifyIdForTracking(product.id),
                          variant_id:
                            formatShopifyIdForTracking(
                              product.variants?.edges?.[0]?.node?.id
                            ) || "",
                          price:
                            product?.priceRange?.minVariantPrice?.amount || "",
                          brand: product.vendor || "",
                          position: Number(index),
                          category: product.productType || "",
                          list: window.location.pathname,
                          variant:
                            formatShopifyIdForTracking(
                              product.variants?.edges?.[0]?.node?.id
                            ) || "",
                        },
                      ],
                },
              },
            });
          }}
        >
          <FixedRatio width="100%" ratio={[[282, 282]]} height={"100%"}>
            <Box
              width="100%"
              height="100%"
              position="relative"
              border="1px"
              borderRadius={"8px"}
              borderColor="grey100"
              bg="white"
            >
              <AddToWishlistButton
                product={product}
                border="none"
                bg="unset"
                position="absolute"
                left={-1}
                bottom={-1}
                zIndex={1}
              />
              <StockLabel product={product} />
              {inStock && <Labels isNew={isNew} inStock={true} />}
              <DiscountLabel product={product} />
              <Box
                position="absolute"
                right={["-5px", -1]}
                bottom={"3px"}
                zIndex={1}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {!small && <AddToCartButton border="none" product={product} />}
              </Box>
              <Box position="relative" height="100%">
                {(product?.images?.edges?.[0]?.node?.url ||
                  product?.images?.edges?.[0]?.node?.originalSrc ||
                  product?.images?.edges?.[0]?.node?.image?.originalSrc) && (
                  <Image
                    layout="fill"
                    objectFit="contain"
                    objectPosition="center"
                    quality={70}
                    style={{ borderRadius: "8px" }}
                    loading="lazy"
                    src={
                      product?.metaImage?.value
                        ? product?.metaImage.value
                        : product?.images?.edges?.[0]?.node?.url ||
                          product?.images?.edges?.[0]?.node?.originalSrc ||
                          product?.images?.edges?.[0]?.node?.image?.originalSrc
                    }
                    alt={
                      product?.images?.edges?.[0]?.node?.altText ||
                      product?.title
                    }
                  />
                )}
              </Box>
            </Box>
          </FixedRatio>

          <Box mt={"10px"} flex={1} width="100%" textAlign="left">
            <Text
              fontSize={["18px", small ? 1.75 : 2.25]}
              fontWeight={600}
              css={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {product?.title}
            </Text>
          </Box>
          <Box mt={"3px"} display="flex" justifyContent="space-between">
            <Text
              color="grey600"
              fontWeight={500}
              lineHeight="22.4px"
              fontSize={["16px", small ? 1.75 : "16px"]}
              display="inline-flex"
            >
              {product?.priceRange?.minVariantPrice?.amount !==
              product?.priceRange?.maxVariantPrice?.amount
                ? "Vanaf "
                : ""}
              {price
                ? "€" + price
                : formatPrice(product).includes("NaN")
                ? product.price
                : formatPrice(product)}
            </Text>
            {((!!review && review !== "0") || product?.metafield?.value) &&
              isShowRating && (
                <Box alignItems={"center"} display={"flex"}>
                  <Text
                    fontWeight={500}
                    lineHeight="16px"
                    fontSize={["16px", small ? 1.75 : "16px"]}
                  >
                    {review || product.metafield.value}
                  </Text>
                  <Icon
                    ml={0.5}
                    lineHeight="16px"
                    fontSize={["15.5px", small ? 1.75 : "15.5px"]}
                    color="oldPink"
                    icon={Star}
                    pb={"1px"}
                  />
                </Box>
              )}
          </Box>
        </Button>
      </HoverArea>
    );
  }
);
