import { useEffect, useState } from "react";
import { Box, Flex, Heading, Spinner, Text, css } from "@storyofams/react-ui";
import axios from "axios";
import Image from "next/image";
import { useQuery } from "react-query";
import { useShopify } from "~context";
import { storyblokSdk } from "~lib/storyblok/client";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox";
import { Input } from "../Input";
import { Modal } from "../Modal";
import { RichText } from "../RichText";

export const SignupPopup = ({ isOpen, close }) => {
  const { data, isLoading } = useQuery(["wishlistPopup"], () =>
    storyblokSdk.wishlistPopup({
      slug: "wishlist-popup",
    })
  );
  if (!data?.ContentNode?.content?.isEnabled) return null;
  return (
    <Modal
      overflow="visible"
      isOpen={isOpen}
      close={() => {
        close();
      }}
    >
      <Box
        p={["20px", "30px"]}
        bg="#F7EFE7"
        position={"relative"}
        borderRadius={"8px"}
      >
        {data.ContentNode?.content?.hero.filename && (
          <Box
            mx="auto"
            top={-8}
            left={0}
            right={0}
            width={130}
            height={130}
            position={"absolute"}
          >
            <Image
              layout="fill"
              src={data.ContentNode?.content?.hero.filename}
            />
          </Box>
        )}
        <Box textAlign={"center"}>
          {data.ContentNode?.content?.image?.filename && (
            <Box
              width={"100%"}
              height={"200px"}
              mt={3}
              position={"relative"}
              css={css({
                img: {
                  borderRadius: "md",
                },
              })}
            >
              <Image
                objectFit="cover"
                layout="fill"
                src={data.ContentNode?.content?.image?.filename}
              />
            </Box>
          )}
          <Text
            fontFamily={"DINPro"}
            fontWeight={700}
            fontSize={"28px"}
            mt={"30px"}
            mb={["20px", "30px"]}
          >
            {data.ContentNode?.content?.title}
          </Text>
          <RichText
            text={data.ContentNode?.content?.body}
            fontWeight={400}
            fontSize={"18px"}
            lineHeight={"28px"}
            px={2}
            mb={["20px", "30px"]}
          />
          {/* <Flex justifyContent={"space-between"} px={2} mb={["20px", "40px"]}>
            <Box>
              <Image
                width={50}
                height={50}
                src="https://a.storyblok.com/f/125270/103x104/fbac7b7f2a/step1.png"
              />
              <Text fontWeight={700} fontSize={"18px"} my={"10px"}>
                Stap 1
              </Text>
              <Text>Schrijf je in</Text>
            </Box>
            <Box ml="15px">
              <Image
                width={50}
                height={50}
                src="https://a.storyblok.com/f/125270/130x114/1ff79956ed/step2.png"
              />
              <Text fontWeight={700} fontSize={"18px"} my={"10px"}>
                Stap 2
              </Text>
              <Text>Verdien coins</Text>
            </Box>
            <Box>
              <Image
                width={50}
                height={50}
                src="https://a.storyblok.com/f/125270/126x107/a0fff28028/step3.png"
              />
              <Text fontWeight={700} fontSize={"18px"} my={"10px"}>
                Stap 3
              </Text>
              <Text>Wissel je coins in</Text>
            </Box>
          </Flex> */}
          <Button
            variant="primary"
            width={"100%"}
            height={"48px"}
            as="a"
            href="/api/auth/login"
          >
            {data.ContentNode?.content?.cta}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
